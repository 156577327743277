import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";

const {div, h1, h2, p, img, br, hr, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("Programm"),
                img({src: require('/assets/img/zeitplan.png'), style:"max-width: 90%; margin: 1em auto; display: block", height: "auto"}),
                h1("Exkursionen"),
                br(),
                h2("Eisenbahnmuseum"),
                p("Mit der Bahn (Dank Riedbahnsanierung über einen Umweg) geht es ins Eisenbahnmuseum nach Kranichstein. Dort gibt es eine Führung mit Fakten zum modernen und historischen Eisenbahnsystem. Zu sehen gibt es den Lokschuppen voller Dampflokomotivschätze, jede Menge Eisenbahnwagen und das Lehrstellwerk. Mit einer Modelleisenbahn und zwei mechanischen Stellwerken werden die Prinzipien des deutschen Eisenbahnsystems vorgeführt. ACHTUNG: Selbst zu tragender Unkostenbeitrag in Höhe von: 5 €."),
                h2("Gutenberg-Museum"),
                p("Mainz - Die Gutenbergstadt! Aber wer war Johannes Gutenberg eigentlich, dass er sogar Namenspatron unserer schönen Universität wurde? Das mitten in der Altstadt gelegene Gutenbergmuseum beleuchtet die historische Person genauso wie die Entwicklung des Buchdrucks als Meilenstein der Kommunikation. Inklusive Live-Druckvorführung an originalgetreuer Druckpresse!"),
                h2("Institutsführung"),
                p("Mainz - Warum eigentlich in Mainz Physik studieren? Lerne das Institut für Physik und Kernphysik an der Johannes Gutenberg-Universität kennen und lass dir von den verschiedenen Arbeitsgruppen (wir haben mehr als 50) die Vielfalt der Mainzer Physik zeigen. Mit etwas Glück dürft ihr auch einen Blick in das nagelneue Gebäude 'Centrum für Fundamentale Physik II' werfen und etwas über Forschung mit unserem Exzellenzcluster PRISMA+ erfahren."),
                h2("Landtag"),
                p("Der Mainzer Landtag ist das Herzstück der rheinland-pfälzischen Politik. Eine einstündige Führung gewährt uns Einblicke in die politische Arbeit und die Entscheidungsprozesse unseres Bundeslandes. Unerlässlich für all jene, die sich für Demokratie und Politik interessieren. ACHTUNG: Einlass nur mit amtlichen Lichtbildausweis und Angabe des Namens wie auf dem Ausweisdokument (siehe eigenes Feld dafür)."),
                h2("MAMI - Mainzer Mikrotron"),
                p("Natürlich darf eine Besichtigung des Mainzer Mikrotron (MAMI), dem größten Mikrotron der Welt, nicht fehlen. Dieser erzeugt einen quasi kontinuierlichen Elektronenstrahl mit einer Energie von bis zu 1,5 GeV und wird für Experimente in der Kern- und Hochenergiephysik verwendet. Laut aktuellem Plan können sowohl sämtliche Beschleunigerstufen wie auch die Experimentierhallen besucht werden. Bitte beachtet auch die Sicherheitshinweise unter https://www.kernphysik.uni-mainz.de/sicherheitshinweise-mami-fuehrung"),
                h2("Stadtführung"),
                p("Mainz verbindet reiche Geschichte mit moderner Kultur und bietet Besuchern eine Mischung aus beeindruckender Architektur, historischen Stätten und lebendiger Lebensart. Die Stadtführung ermöglicht es euch, tief in die antike Vergangenheit einzutauchen und die Spuren römischer Kultur und Religion hautnah zu erleben."),
                h2("Zitadelle"),
                p("Am Rand der Mainzer Altstadt thront die alte Zitadelle auf den Ruinen eines römischen Ehrenmals. Dieses geschichtsträchtige Gebäude von einem Teil der stärksten Bundesfestung Deutschlands zu einem lebendigen Kultur- und Veranstaltungsort geworden. Die Führung führt uns durch verschiedene Epochen und in immer tiefere Gewölbe und Tunnel, die der Öffentlichkeit eigentlich unzugänglich sind. ACHTUNG: Selbst zu tragender Unnkostenbeitrag in Höhe von 5 €.")
            ]
        ))
    }
};
export default page;
