import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";

const {div, h1, h2, p, img, br, hr, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("Merch"),
                p("Euren Merch könnt ihr bis zum 05.10.2024 in der Anmeldung bestellen."),
                h2("T-Shirt (weiß)"),
                a({href: "https://www.better-shirts.de/stanley-stella-creator-2-0-bio-t-shirt-beste-qualitaet.html"}, "Herstellerseite mit Bild vom T-Shirt"),
                p("Preis: 13 €"),
                h2("Kaputzenpullover (schwarz)"),
                a({href: "https://cotton.de/produkt/drummer-2-0/"},  "Herstellerseite mit Bild vom Pullover"),
                p("Preis: 40 €"),
                h2("Schürzen (schwarz)"),
                a({href: "https://www.stickerei-stoiber.de/latzschuerzen-bedrucken-besticken.html"},  "Herstellerseite mit Bild von der Schürze"),
                p("Preis: 13 €"),
                h2("Handtuch (irongrey)"),
                a({href: "https://www.merkur-werbemittel.de/artikel/handtuch_aus_bio-baumwolle/57684/770"},  "Herstellerseite mit Bild vom Handtuch"),
                p("Preis: 20 €"),
                div({class: style.sponsor}, [
                    img({src: require('/assets/img/logo.svg')}),
                    img({src: require('/assets/img/logo_invertiert.svg')}),
                    img({src: require('/assets/img/logo_einfarbig.svg')}),
                    img({src: require('/assets/img/zapfevLogo.svg')}),
                ]),br(),
            ]
        ))
    }
};
export default page;
