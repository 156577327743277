import * as m from "mithril";
import HomeView from "./pages/Home";
import NewsView from "./pages/News";
import FachschaftView from "./pages/Fachschaft";
import MemeView from "./pages/memes";
import OrgaView from "./pages/Orga";
import ZaPFView from "./pages/ZaPF";
import AwarenessView from "./pages/Awareness";
import AnreiseView from "./pages/Anreise";
import ProgrammView from "./pages/Programm";
import MerchView from "./pages/Merch";
import PacklistView from "./pages/Packliste";
import "../assets/css/main.css";

require("normalize.css");

m.route.prefix = '#!'
const el = document.getElementById("content");
m.route(el, "/", {
    "/": HomeView,
    "/news": NewsView,
    "/fachschaft": FachschaftView,
    "/orga": OrgaView,
    "/zapf": ZaPFView,
    "/memes": MemeView,
    "/awareness": AwarenessView,
    "/anreise": AnreiseView,
    "/programm": ProgrammView,
    "/merch": MerchView,
    "/packliste": PacklistView,
});
