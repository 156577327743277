import * as m from "mithril";

const {p} = require("hyperscript-helpers")(m);


const a240604 = {
    title: "Webseite endlich veröffentlicht",
    date: "4.Juni 2024",
    id: "240604",
    shortext: [
        p("Darauf hat jedes ZaPFika gewartet."),
        p(" Die ZaPF in Meenz hat jetzt endlich eine Veröffentlichung im World Wide Web, zu deutsch Welt Breit Netz."),
    ],
    text: [
        p("Darauf hat jedes ZaPFika gewartet."),
        p(" Die ZaPF in Meenz hat jetzt endlich eine Veröffentlichung im World Wide Web, zu deutsch Welt Breit Netz."),
    ]
};

export default a240604;
