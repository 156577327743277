import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/orga.module.css";

const {div, h1, ul, p, img, li, h2, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.hero}, [
                h1("Unsere Orga"),
                img({src: require('/assets/img/orga.png')}),
                p("Die MEENZer wünschen Euch eine wunderschöne ZaPF in Meenz 2024.")
            ]),
        );
    }
};
export default page;
