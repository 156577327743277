.hero {
    width: 100%;
    margin: auto;
    text-align: center;

}

.hero img {
    width: 80%;
    height: auto;
    margin: 1em 0;
    border-radius: 10px;
}

.page p{
    font-size: 150%;
    margin: 0;
}

.page h1{
    text-align: center;
}