import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/about.module.css";

const {div, h1, ul, p, img, li, h2, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            h1("Memes"),
            div({class: style.memes}, [
                img({src: require('/assets/img/memes/20.jpeg')}),
                img({src: require('/assets/img/memes/19.jpeg')}),
                img({src: require('/assets/img/memes/18.jpeg')}),
                img({src: require('/assets/img/memes/17.jpeg')}),
                img({src: require('/assets/img/memes/16.jpeg')}),
                img({src: require('/assets/img/memes/15.jpeg')}),
                img({src: require('/assets/img/memes/14.jpeg')}),
                img({src: require('/assets/img/memes/13.jpeg')}),
                img({src: require('/assets/img/memes/12.jpeg')}),
                img({src: require('/assets/img/memes/11.jpeg')}),
                img({src: require('/assets/img/memes/10.jpeg')}),
                img({src: require('/assets/img/memes/9.jpeg')}),
                img({src: require('/assets/img/memes/8.jpeg')}),
                img({src: require('/assets/img/memes/7.jpeg')}),
                img({src: require('/assets/img/memes/6.jpeg')}),
                img({src: require('/assets/img/memes/5.jpeg')}),
                img({src: require('/assets/img/memes/4.jpeg')}),
                img({src: require('/assets/img/memes/3.jpeg')}),
                img({src: require('/assets/img/memes/2.jpg')}),
                img({src: require('/assets/img/memes/1.jpg')}),]
            )
        );
    }
};
export default page;
