.page {
    text-align: justify;
    letter-spacing: -.01em;
}

.page h2{
    padding-bottom: .5em;
}

.images{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;
}

.images img{
    margin: 1em auto;
    width: 50%;
    height: auto;
}

.page p{
    margin: 0;
}

.hero {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

@media screen and (max-width: 1320px) {
    .images img{
        width: 100%;
    }

    :global .leaflet-container {
        margin-top: 1em;
        width: 100%!important;
    }
}

.herotext{
    width: 400px;
}

.hero img {
    width: 33vw;
    float: right;
    height: 33vw;
    margin-left: 2em;
    margin-bottom: auto;
    border-radius: 10px;
}

.sponsor {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: white;
}

.sponsor img {
    height: 10vw;
    margin: 1em;
}


@media only screen and (max-width: 1000px) {
    .hero img {
        display: none;
    }

    .sponsor img {
        width: 33vw;
        height: auto;

    }

}
