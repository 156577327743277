.bar {
    display: flex;
    align-items: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    height: 4em;
    justify-content: space-between;
}

#linkbar {
    margin-bottom: 2em;
    background-color: #779f32;
    height: 2.5em;
    padding-left: 10svw;
    padding-right: 10svw;
    order: 10000;
}

#tagungsbar {
    display: none;
    flex-direction: column;
    position: absolute;
    height: fit-content;
    padding: 0 1em;
    right: 8.7em;
    top: 4em;
    text-align: center;
    background-color: var(--zapf-meenz-red);
    box-shadow: -2px 18px 30px black;
    z-index: 10000;
}

#ueberunsbar {
    display: none;
    flex-direction: column;
    position: absolute;
    height: fit-content;
    padding: 0 1em;
    right: 22.3em;
    top: 4em;
    text-align: center;
    background-color: var(--zapf-meenz-red);
    box-shadow: -2px 18px 30px black;
    z-index: 10000;
}


#vorderzapfbar {
    display: none;
    flex-direction: column;
    position: absolute;
    height: fit-content;
    padding: 0 1em;
    right: .5em;
    top: 4em;
    text-align: center;
    background-color: var(--zapf-meenz-red);
    box-shadow: -2px 18px 30px black;
    z-index: 10000;
}

#navbar {
    background-color: var(--zapf-meenz-red);
}

.title {
    font-size: 200%;
    color: rgb(255, 255, 255);
}

.links{
    display:flex;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.links .link{
    height: fill-available;
    height: -webkit-fill-available;
    height: -moz-fill-available;
}

.link {
    padding: 1em;
    color: white;
    text-decoration-line: none;
    text-wrap: nowrap;
    display: inline;
    cursor: pointer;
}

.link:hover {
    box-shadow: 1px 1px 18px rgb(61, 61, 61);
    background-color: white;
    color: var(--zapf-meenz-red);
}

#linkbar .link {
    padding: 0.3em;
}

#linkbar .link:hover {
    color: #779f32
}

#tagungsbar .link {
    padding: 0.5em 1em;
    width: 100%;
}

#ueberunsbar .link {
    padding: 0.5em 1em;
    width: 100%;
}

#vorderzapfbar .link {
    padding: 0.5em 1em;
    width: 100%;
}

.logo, .logo img {
    height: 100%;
}

@media only screen and (max-width: 800px) {
    #tagungsbar {
        position: static;
        box-shadow: none;
        border-top: 1px solid white;
    }

    #ueberunsbar {
        position: static;
        box-shadow: none;
        border-top: 1px solid white;
    }

    #vorderzapfbar {
        position: static;
        box-shadow: none;
        border-top: 1px solid white;
    }

    .bar {
        font-size: 80%;
    }
}