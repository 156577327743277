import * as m from "mithril";
import NavBar from "./Navbar";
import FootBar from "./Footbar";
import css from "/assets/css/components/pagetemplate.module.css";


const {div} = require("hyperscript-helpers")(m);

interface Attrs {
    css?: string
}

const PageTemplate: m.Component<Attrs> = {
    view(vnode: m.Vnode<Attrs>) {
        return [
            m(NavBar),
            div({class: css.view + ' ' + (vnode.attrs.css ?? "")}, vnode.children),
            m(FootBar)]
    }
}

export default PageTemplate;
