import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";
import articles from "../news/index";

const {div, h1, h2,i, p, img, br, hr, a} = require("hyperscript-helpers")(m);

let news = []
articles.forEach((item) => {
    news.push(
        hr({id:item.id}),
        h2(item.title),
        i(item.date),
        item.text)
})

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("BREAKING NEWS"),
                news
            ])
        );
    }
};
export default page;
