import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/awareness.module.css";

const {div, h1, h2, p, img, br, hr, a, wbr} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1('Vertrauens',wbr(),'personen'),
                div([img({src: require("/assets/img/mika_square.jpg"), alt: "Mika Möhring (Mainz)"}),p("Mika Möhring (Mainz)"),p("mika@zapf.in"),p("Hey, ich bin Mika und studiere im 5. Semester Physik hier in Mainz. Ich habe in der Vergangheit zwei Jahre in der Jugendarbeit viele nützliche Erfahrungen sammeln können und immer ein offenes Ohr für Angelegenheiten aller Art")]),
                div([img({src: require("/assets/img/ginger_square.jpg"), alt: "Ginger Ihde (Mainz)"}),p("Ginger Ihde (Mainz)"),p("ginger@zapf.in"),p("Die ZaPF in Meenz wird meine 3. ZaPF sein, nachdem ich in Düsseldorf und Kiel vor allem AKs zu den Themen Lehramt, Social Media und Awareness besucht habe. In Mainz studiere ich bereits seit 2020 die Fächer Physik und Mathematik auf Lehramt und hoffe, dass meine Erfahrungen hier und auf bisherigen ZaPFen mir dabei helfen können eine vermittelnde Rolle zwischen Teilnehmika und Orga einzunehmen.")]),
                div([img({src: require("/assets/img/bernie.jpg"), alt: "Bernadette B. (Awarenessgremium)"}),p("Bernadette B. (AwGrem)"),p("bernadette@zapf.in"),p("Ich schreibe gerade an meiner Bachelorarbeit, bin aber trotzdem sowohl bei Ersti Tagen, als auch bei Unifeten als Awareness Person tätig und nehme mir gerne die Zeit anderen Menschen zuzuhören und zu helfen.")]),
                div([img({src: require("/assets/img/chris.png"), alt: "Chris (Awarenessgremium)"}),p("Chris (AwGrem)"),p("chris@zapf.in"),p("Ich habe in Marburg Physik studiert und fahre noch weiterhin als alter Sack auf ZaPFen. Dort beschäftige ich mich mit vielen Themen, zuletzt primär mit Awareness und wie wir die ZaPF inklusiver für alle gestalten können.")]),
                div([img({src: require("/assets/img/andy.jpg"), alt: "Andy D."}),p("Andy D."),p("andy@zapf.in"),p("Ich hab über die Jahre viel Erfahrung mit der ZaPF und ihren Strukturen gesammelt und hab als Alumnus auch die Kapazitäten, mich voll auf eure Anliegen zu konzentrieren. Deshalb stelle ich mich gern als Vertrauensperson zur Verfügung")]),
                div([img({src: require("/assets/img/günther.png"), alt: "Günther H."}),p("Günther H."),p("guenther@zapf.in"),p("Huhu und Grüße aus Norwegen (wo ich diesen Text verfasse) ich bin der Günther/Olaf (Er/Ihm) und häufig für kochen verantwortlich. Aber ich bin auch als Mediator tätig und im Rahmen meiner Arbeit mit Jugendlichen muss ich das immer wieder anwenden. Ich habe ADHS was mir manchmal eine besondere Sicht auf Dinge verleiht. Wenn ihr mich als Vertrauensperson wählt würde ich mich freuen, aber die anderen sind ja auch mindestens genauso Toll. Also wenn es nichts wird verstehe ich das genauso.")]),
                div([img({src: require("/assets/img/raja.png"), alt: "Raja H."}),p("Raja H."),p("raja@zapf.in"),p("Hey ihr Lieben, Ich bin Raja und das ist meine vierte ZaPF. Ich weiß, dass ZaPFen anstrengend und belastend sein können und genau deswegen darfst du gerne jederzeit auf mich zukommen, falls du reden möchtest oder einfach nur eine Auszeit brauchst. Ich bin gerne für dich da.")]),
                div([img({src: require("/assets/img/steph.png"), alt: "Steph W."}),p("Steph W."),p("steph@zapf.in"),p("Ich war schon einige Male Vertrauensperson und nehme mir immer gerne Zeit, um den Menschen zuzuhören und so meinen Teil dazu beizutragen, dass es allen auf der ZaPF gut geht.")]),
                div([img({src: require("/assets/img/tim.png"), alt: "Tim N."}),p("Tim N."),p("tim@zapf.in"),p("Hallöchen, als ausgebildeter Mental Health First Aid (MHFA) Ansprechpartner durfte ich schon auf Unifeten helfen und durch die Fachschaft für unseren Studies und Erstis helfen. Gerne würde ich auch dieses mal auf der ZaPF wieder als Vertrauensperson dir ein offenes Ohr bieten.")]),
                div([img({src: require("/assets/img/vicky.png"), alt: "Victoria (Vicky) S."}),p("Victoria (Vicky) S."),p("vicky@zapf.in"),p("22 ZaPFen, 7 Geschwister und langjähriges Mentikon. Bachelor, Master und Doktorarbeit mit allen Höhen und Tiefen überstanden. Kann Menschen mit unwichtichtigen Fakten zu Knochen ablenken. Sehr gut im Kekse- und Kuchenbacken und Zuhören. Emotional support Penguin")]), 
            ]
        ))
    }
};
export default page;
