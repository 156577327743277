import * as m from "mithril";

const {p,a } = require("hyperscript-helpers")(m);


const a240604 = {
    title: "Habemus Merch-Preise!",
    date: "30.Oktober 2024",
    id: "241030",
    shortext: [
        p("Leider haben sich die Maximalpreise als richtig herausgestellt."),
    ],
    text: [
        p("Wir haben jetzt die finalen Preise für den Merch und es ergaben sich keine Vergünstigung."),
    ]
};

export default a240604;
