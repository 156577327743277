import * as m from "mithril";
import css from "/assets/css/components/footbar.module.css";

const {div, a, h1, hr, p} = require("hyperscript-helpers")(m);

const Navbar: m.Component = {
    view() {
        return [div({}, [
            hr(),
            div({class: css.footBar}, [
                h1("Kontakt"),
                a({href: "https://fachschaft.physik.uni-mainz.de"}, "Fachschaft Physik/Meteorologie"),
                p("Staudingerweg 9"),
                p("Raum 01-526"),
                p("55128 Mainz"),
                p("Telefon: +49 6131 39-23272"),
                p("Mail: zapf-in-mainz (at) uni-mainz.de"),
                a({href: "https://fachschaft.physik.uni-mainz.de/fachschaftsrat/impressum/"}, "Impressum"),

            ])])];
    },
};

export default Navbar;
