.mainview h1{
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
}

.mainview div img{
    border-radius: 3px;
}

.mainview div{
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    height: intrinsic;
    margin: 1em auto;
    padding: 0 25px;
}

.mainview div *{
    text-align: center;
    width: 100%;
    margin: .1em;
    line-height: 1.5em;
}


.mainview div :nth-child(2){
    font-size: 150%;
    word-break: break-word;
}

.mainview div :nth-child(4){
    text-align: justify;
    letter-spacing: .0em;
    font-size: 90%;
    margin-top: 1em;
}

.mainview{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

@media screen and (max-width: 800px){
    .mainview div{
        width: 80vw;
    }

    .mainview div :nth-child(2){
        margin-top: .5em;
        font-size: 4vw;
    }
    
    .mainview div :nth-child(3){
        font-size: 2.5vw;
        margin-bottom: 2em;
    }
}