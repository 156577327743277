.footBar {
    padding: 0 3em 3em 3em;
}

.footBar p {
    margin: 0;
}

hr {
    margin: 2em 0
}