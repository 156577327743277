.page {
    text-align: justify;
    letter-spacing: .0em;
}

.hero {
}

.hero img {
    width: 33vw;
    float: right;
    height: 33vw;
    margin-left: 2em;
    margin-bottom: auto;
    border-radius: 10px;
}

.sponsor {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: white;
}

.sponsor img {
    height: 10vw;
    margin: 1em;
}


@media only screen and (max-width: 1000px) {
    .hero img {
        display: none;
    }

    .sponsor img {
        width: 33vw;
        height: auto;

    }

}
